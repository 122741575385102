import React from "react";
import "./RealEstate.scss";
import leftImg from "../images/img_real_state2.png";
import img1 from '../images/home.png'
import img2 from '../images/lackperrf.png'
import img3 from '../images/utilization.png'
import img4 from '../images/insight.png'
import img5 from '../images/mask.png'
import RealState2 from "./RealState2";



const RealEstate = () => {
  return (
    <>
    {/* <RealState2 /> */}
    <section id="RealEstate">

      <div className="container-fluid mt-5">
        {/* <div className="header"></div> */}


        <div className='RealStateinner'>



        <div className="row d-flex align-items-center">
          <div className="col-sm-6 d-flex justify-content-center">
         <div className="cutImg">

         <img src={img5} className="img-fluid" />

         </div>
          
            {/* <div className="row">
<div className="col-2 img-col">
          <img src={Image} className="img-fluid" alt="Real Estate 1"/>
        </div>
        <div className="col-2 img-col">
          <img src={Image} className="img-fluid" alt="Real Estate 2"/>
        </div>
        <div className="col-2 img-col">
          <img src={Image} className="img-fluid" alt="Real Estate 3"/>
        </div>
        <div className="col-2 img-col">
          <img src={Image} className="img-fluid" alt="Real Estate 4"/>
        </div>
        <div className="col-2 img-col">
          <img src={Image} className="img-fluid" alt="Real Estate 5"/>
        </div>
      </div> */}
          </div>

          <div className="col-sm-6">
            <div className="right">
              <h1>An objective scientific framework</h1>
              <h5>
              The real estate industry is plagued by <span>uncertainty</span> and <span>inefficiency. Outdated practices</span> and a <span>lack of standardised data</span> have created a landscape where crucial decisions are often based on <span>guesswork</span> rather than an objective scientific framework.
              </h5>
              <ul>
                <li>
                  <img
                    src={img1}
                    alt="Inconsistent Valuations"
                  />
                  <span>Inconsistent & Infrequent Appraisals </span>
                </li>
                <li>
                  <img
                    src={img2}
                    alt="Inconsistent Valuations"
                  />
                 <span>Inaccurate Risk Profiles</span>
                </li>
                <li>
                  <img
                    src={img3}
                    alt="Inconsistent Valuations"
                  />
                    <span>Inefficient resource utilization</span>
                </li>
                <li>
                  <img
                    src={img4}
                    alt="Inconsistent Valuations"
                  />
                   <span>Absence of  predictive insights</span>
                </li>
                
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-container">
      <p>
        
        These challenges are compounded by the industry's reliance on <strong>subjective methods</strong> and the <strong>absence of scientific rigour.</strong> Without <strong>standardised metrics</strong> and <strong>scientific data-driven insights</strong>, real estate professionals struggle to make <strong>informed decisions about the present or future</strong> or understand <strong>the risk profile</strong> of their investments. To address these critical problems, a <strong>fundamental shift towards a scientific framework is required.</strong>
      </p>
    </div>
      </div>
      </div>
    </section>
 
    </>
  );
};

export default RealEstate;
